<template>
  <div class="dream_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: false }"></v-header>
      <van-search v-model="searchValue" show-action placeholder="请输入梦见的东西" @search="onSearch">
        <template #action>
          <div @click="onSearch" style="color: #4fc08d;">搜索</div>
        </template>
      </van-search>
    </div>
    <p v-show="list.length!==0" class="info">在<span class="color_red">《梦秘解图》</span>中为您找到<span class="color_red">{{list.length}}</span>个结果</p>
    <div class="tag_box">
      <!-- <span v-for="(item, index) in list" :key="index" class="tag" :style="{ 'background-color' : item.bgColor }" @click="onTagClick(index)">{{item.keyword}}</span> -->
      <div class="tag_list" v-for="(item, index) in list" :key="index" >
        <img :src="item.imgUrl" @click="onTagClick(index)"/>
        <div class="desc" @click="onTagClick(index)">
            <p class="name"><span v-for="(ite,inde) in item.keyword" :key="inde" :class="searchValue.indexOf(ite)!==-1?'color_red':'color_black'">{{ite}}</span></p>
            <p><span class="num1">{{item.num1Pre}}</span><span style="opacity: 0.5;">{{item.num1Tail}}</span></p>
            <p><span class="num2">{{item.num2Pre}}</span><span style="opacity: 0.5;">{{item.num2Tail}}</span></p>
        </div>
        <div class="share" @click="onshare(index)">
            <button>去分享</button>
        </div>
      </div>
    </div>
    <p style="height:1rem;">  </p>
    <v-footer></v-footer>

    <van-dialog v-model="dialogShow" :title="dialogData.keyword" show-cancel-button confirmButtonColor="#4fc08d" class="dialog_box">
      <div class="cnt">
        <!-- <van-image fit="cover" width="100%" height="60vw" :src="dialogData.imgUrl" /> -->
        <van-image fit="fill" width="100%" height="60vw" :src="dialogData.imgUrl" >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
        <p>内容：{{dialogData.keyword}}</p>
        <p>号码1：<span class="num1">{{dialogData.num1Pre}}</span>  <span style="opacity: 0.5;">{{dialogData.num1Tail}}</span></p>
        <p>号码2：<span class="num2">{{dialogData.num2Pre}}</span>  <span style="opacity: 0.5;">{{dialogData.num2Tail}}</span></p>
      </div>
      <br>
    </van-dialog>
  </div>
</template>

<script>
import vFooter from "@/components/footer.vue";
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'


export default {
  components: {
    vHeader,
    vFooter,
  },
  data() {
    return {
      title: this.$route.meta.title,
      colorArr: ["#EE2C2C", "#ff7070", "#EEC900", "#4876FF", "#ff6100",
        "#7DC67D", "#E17572", "#7898AA", "#C35CFF", "#33BCBA", "#C28F5C",
        "#FF8533", "#6E6E6E", "#428BCA", "#5cb85c", "#FF674F", "#E9967A",
        "#66CDAA", "#00CED1", "#9F79EE", "#CD3333", "#FFC125", "#32CD32",
        "#00BFFF", "#68A2D5", "#FF69B4", "#DB7093", "#CD3278", "#607B8B"],
      list: [],
      searchValue: '',
      dialogShow: false,
      dialogIndex: 0,
      showHeader:true
    };
  },
  computed: {
    dialogData() {
      let dialogData = {
        keyword: '',
        imgUrl: '',
        num1Pre: '',
        num2Pre: ''
      }
      if (this.list.length && this.list[this.dialogIndex]) {
        return { ...dialogData, ...this.list[this.dialogIndex] }
      }
      return dialogData
    }
  },
  created() {
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    this.onSearch();

  },
  methods: {
    async onSearch() {
      this.list = []
      const res = await this.$HTTP.post(this, 'API_DREAM_CONTENT_ANON_LISTS', { 'keyword': this.searchValue }, {}, true)
      if (res.data && res.data.length) {
        for (let item of res.data) {
          item.bgColor = this.setColor()
        }
        this.list = res.data
        console.log(this.list)
      }
    },
    setColor() {
      return this.colorArr[Math.floor(Math.random() * 28)]
    },
    onTagClick(index) {
      this.dialogShow = true
      this.dialogIndex = index
    },
    onshare(index){
      console.log('测试分享，待完成。。。',this.list[index])
    }
  }
};
</script>

<style lang="less">

.info{
    width: 100%;
    height: 0.8rem;
    margin: 0;
    padding-top: 0.2rem;
    font-size: 0.3rem;
    text-align: center;
    background-color: white;
}
.color_red{
  color: red;
}
.color_black{
  color:black;
}
.dream_page {
  .tag_box {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 0 16px;

    .tag {
      align-items: center;
      padding: 0 4px;
      color: #fff;
      font-size: 13px;
      line-height: 16px;
      border-radius: 1px;
      padding: 2px 6px;
      margin: 0 14px 14px 0;
    }
    .tag_list{
      display: inline;
      width: 4.5rem;
      height: 4.5rem;
      margin: 0.1rem;
      background-color: #b7afac19;
      img{
        float: left;
        width:2rem;
        height:3rem;
        margin-left: 0.2rem;
        margin-top: 0.2rem;
        border-radius: 0.1rem;
      }
      .desc{
        width: 2.3rem;
        margin-top: 0.2rem;
        float: left;
        p{
          font-size: 0.4rem;
          margin: 0.2rem;
        }
        .name{
          margin: 0.6rem 0.3rem;
        }
        .num1{
          margin: 0.1rem;
        }
        .num2{
          margin: 0.1rem;
        }
      }
      .share{
        margin-top: 3.2rem;
        text-align: center;
        button{
          border-radius: 1rem;
          border: none;
          width: 3rem;
          height: 0.7rem;
          font-size: 0.3rem;
          color:rgba(46, 117, 209, 0.853);
          background-color: rgba(46, 116, 209, 0.185);
        }
      }
    }
  }
  .dialog_box {
    .cnt {
      width: 88%;
      margin: auto;
      img {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
        color: black;
      }
    }
  }
}
</style>
